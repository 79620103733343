import React from "react"
import {
  BlogCard,
  Container,
  BlogImage,
  BlogDescription,
  BlogTitle,
  BlogReadTime,
} from "../../../ToolsStyles/BlogSectionStyled"

import BlogImage1 from "../../../ToolBlogImages/Password.avif"
import BlogImage2 from "../../../ToolBlogImages/EditPassword.avif"
import BlogImage3 from "../../../ToolBlogImages/PritSecuredPDF.avif"
import BlogImage4 from "../../../ToolBlogImages/WetSignature.avif"

import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"
import { navigate } from "gatsby"
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1174 },
    items: 4,
  },
  laptop: {
    breakpoint: { max: 1174, min: 900 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 900, min: 400 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 400, min: 0 },
    items: 1,
  },
}
const BlogSection = () => {
  return (
    <Container>
      <Carousel responsive={responsive}>
        <BlogCard
          onClick={() =>
            navigate("/blog/guides/how-to-password-protect-a-pdf-windows-10/")
          }
        >
          <BlogImage src={BlogImage1} alt="blog-image" />
          <BlogTitle>
            3 Ways | How to Password Protect a PDF in Windows 10 (2023)
          </BlogTitle>
          <BlogDescription>
            Learn the 3 best ways on how to password protect a pdf windows 10 in
            2023! We've created this helpful guide with step-by-step
            instructions and a helpful FAQ!
          </BlogDescription>
          <BlogReadTime>6 min read</BlogReadTime>
        </BlogCard>
        <BlogCard
          onClick={() =>
            navigate("/blog/guides/how-to-edit-password-protected-pdf/")
          }
        >
          <BlogImage src={BlogImage2} alt="blog-image" />
          <BlogTitle>
            How to Edit a Password Protected PDF: Everything You Need to Know
          </BlogTitle>
          <BlogDescription>
            Learn everything you need to know about how to edit a password
            protected PDF. Multiple methods + helpful tips and step-by-step
            instructions in this guide.
          </BlogDescription>
          <BlogReadTime>4 min read</BlogReadTime>
        </BlogCard>
        <BlogCard
          onClick={() => navigate("/blog/guides/how-to-print-a-secured-pdf/")}
        >
          <BlogImage src={BlogImage3} alt="blog-image" />
          <BlogTitle>How to Print a Secured PDF</BlogTitle>
          <BlogDescription>
            Learn how to print a secured PDF instantly! Step-by-step guide with
            full instructions, free and paid methods.
          </BlogDescription>
          <BlogReadTime>3 min read</BlogReadTime>
        </BlogCard>
        <BlogCard
          onClick={() =>
            navigate("/blog/guides/how-to-do-wet-signature-on-pdf/")
          }
        >
          <BlogImage src={BlogImage4} alt="blog-image" />
          <BlogTitle>
            How to do a Wet Signature on PDF (3 Different Ways + Tips)
          </BlogTitle>
          <BlogDescription>
            Learn how to do a wet signature on PDF on Windows today! We gathered
            the best methods, and helpful tips on adding a wet signature to
            PDFs.
          </BlogDescription>
          <BlogReadTime>5 min read</BlogReadTime>
        </BlogCard>
      </Carousel>
    </Container>
  )
}

export default BlogSection
