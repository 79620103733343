import React, { useEffect } from "react"
import styled from "styled-components"

const PdfdisplayStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;

  @media (max-width: 440px) {
    justify-content: center;
  }
  min-height: 283px;
  min-width: max-content;
  width: 100%;
  max-width: max-content;

  img {
    max-width: max-content;
    margin: 0;
    border: 1px solid rgba(0, 0, 0, 0);
    transition: border 0.5s, outline 0.5s;
    outline: rgba(216, 219, 253, 0) solid 10px;
  }
  /* :hover {
    img {
      outline: rgba(216, 219, 253, 0.5) solid 10px;

      border: 1px solid #425466;
    }
  } */
`
const Name = styled.div`
  font-size: 15px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #0b263f;
  margin-top: 16px;
  max-width: 200px;
  min-height: 48px;
  max-height: 48px;
  display: block;
  white-space: normal;

  height: 48px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  @media (max-width: 400px) {
    display: none;
  }
`

const ImageHolder = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 13px 27px -5px rgba(50, 50, 93, 0.25),
    0px 8px 16px -8px rgba(0, 0, 0, 0.3);

  @media (max-width: 400px) {
    img {
      max-width: 140px;
      max-height: 194px;
    }
  }
  .check {
    position: absolute;
    opacity: 0;
    transition: opacity 0.2s;
    z-index: 10;
    right: 0;
    top: 0;
    transform: translate(12px, -12px);
    circle {
      transition: fill 0.2s;
      fill: #cdcdcd;
    }
    path {
      transition: stroke 0.2s;
      stroke: #cdcdcd;
    }
  }
`

function PdfdisplayProtect({ element, name, order }) {
  return (
    <PdfdisplayStyle
      className={`pdfdisplay ${"checked"}`}
      id={`pdfdisplay` + order}
    >
      <ImageHolder className={`${"checked"}`}>
        <img src={element} alt="Pdf display" draggable="false" />
      </ImageHolder>
      <Name>{name} </Name>
    </PdfdisplayStyle>
  )
}

export default PdfdisplayProtect
