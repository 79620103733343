import client from "../toolApi"
import { DataLayer } from "../../helpers/GA"
export const ProtectFile = (data, setDownload) =>
  client
    .post("/uploadNew", data.formData)
    .then(res => {
      DataLayer("Online tool", "Upload", "Protect")

      window.fileName = res.data.message[0]
      client
        .get(
          `/protectTool?password=${data.password}&fileName=${window.fileName}`
        )
        .then(res => {
          DataLayer("Online tool", "Protect", "Protect")
          client
            .get(`/downloadProtect?fileName=${window.fileName}`, {
              responseType: "blob",
            })
            .then(res => {
              console.log("Downloaded: ", res)
              const link = document.createElement("a")
              setDownload(link)
              link.href = URL.createObjectURL(new Blob([res.data]))
              link.download = `${data.fileName}_protected.pdf`
              document.body.appendChild(link)
              link.click()
              DataLayer("Online tool", "Download", "Protect")
            })
            .catch(() => {
              DataLayer("Online tool", "Error download", "Protect")
            })
        })
        .catch(() => {
          DataLayer("Online tool", "Error protect", "Protect")
        })
    })
    .catch(() => {
      DataLayer("Online tool", "Error upload", "Protect")
    })
