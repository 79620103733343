import React from "react"
import {
  Container,
  Title,
  Description,
  DescriptionBox,
  ImageContainter,
  SvgHolder,
} from "../../../ToolsStyles/ShortDescriptionStyled"
import PDFframe from "../../../merge/MergeAssets/MergePDF.jpg"

import Desc1 from "../../../../../icons/tools/lp/merge/DescIcon1.svg"
import Desc2 from "../../../../../icons/tools/protect/ControllerIcon.svg"
import Desc3 from "../../../../../icons/tools/lp/merge/DescIcon3.svg"
import Desc4 from "../../../../../icons/tools/lp/merge/DescIcon4.svg"
import Desc5 from "../../../../../icons/tools/lp/merge/DescIcon5.svg"
import Desc6 from "../../../../../icons/tools/lp/merge/DescIcon6.svg"

const ShortDescriptions = () => {
  return (
    <Container>
      <DescriptionBox>
        <SvgHolder>
          <Desc1 />
        </SvgHolder>
        <Title>No Installation or Sign-up required</Title>
        <Description>
          No downloading software, no signing up necessary. Just visit this
          webpage from your browser, and get started!
        </Description>
      </DescriptionBox>

      <DescriptionBox>
        <SvgHolder>
          <Desc2 />
        </SvgHolder>
        <Title>Why Password Protect a PDF?</Title>
        <Description>
          Keep your private files private! Prevent unwanted viewing, opening,
          and editing of your PDF documents.{" "}
        </Description>
      </DescriptionBox>

      <DescriptionBox>
        <SvgHolder>
          <Desc3 />
        </SvgHolder>
        <Title>Safe & Secure — Your Privacy Matters to Us</Title>
        <Description>Uploaded files are deleted after 24 hours.</Description>
      </DescriptionBox>

      <DescriptionBox>
        <SvgHolder>
          <Desc4 />
        </SvgHolder>
        <Title>What PDFs Can I Protect?</Title>
        <Description>
          Add a password to any PDF document that you want to make more secure
          with this free, online tool.
        </Description>
      </DescriptionBox>

      <DescriptionBox>
        <SvgHolder>
          <Desc5 />
        </SvgHolder>
        <Title>100% Free</Title>
        <Description>Our PDF password tool is 100% free to use.</Description>
      </DescriptionBox>

      <DescriptionBox>
        <SvgHolder>
          <Desc6 />
        </SvgHolder>
        <Title>Works on Any Device</Title>
        <Description>
          Instantly add a password to your PDF in your browser, on any device!
          Windows, Mac, Chrome, Linux — you name it!
        </Description>
      </DescriptionBox>
    </Container>
  )
}

export default ShortDescriptions
