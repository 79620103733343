import React from "react"
import ReactDOMServer from "react-dom/server"

import PdfdisplayProtect from "../protect/PdfdisplayProtect"
export default function displayPdfSyncProtect(
  pdfData,
  fileData,
  appendTo,
  order,
  setFileRendered,
  removePassProtected,
  setErrorFiles,
  ErrorPopup,
  setPageNum
) {
  return new Promise(async (resolve, reject) => {
    let protectedPass = false
    const pdf = await window.pdfjsLib
      .getDocument({
        data: pdfData,
      })
      .promise.catch(() => {
        console.log("Password protected: ", fileData.name)
        removePassProtected(fileData.name)
        setErrorFiles(erF => [...erF, fileData.name])

        protectedPass = true
      })

    if (protectedPass) {
      setFileRendered(order)

      return resolve(false)
    }

    const pages = pdf.numPages

    window.pages = pages
    setPageNum(pages)

    for (let i = 0; i < 1; i++) {
      const page = await pdf.getPage(i + 1)
      const viewport = page.getViewport({ scale: 0.337 })

      const canvas = document.createElement("canvas")

      const context = canvas.getContext("2d")
      canvas.height = viewport.height
      canvas.width = viewport.width
      canvas.style.marginDown = "64px"

      const renderContext = {
        canvasContext: context,
        viewport: viewport,
      }

      await page.render(renderContext).promise

      var dataURL = canvas.toDataURL()

      let str = JSON.stringify({ name: fileData.name, size: fileData.size })
      setFileRendered((i + 1) / pages)

      let pdfHolder = document.getElementById(appendTo)

      pdfHolder.innerHTML += ReactDOMServer?.renderToString(
        <PdfdisplayProtect element={dataURL} name={fileData.name} order={i + 1}>
          {" "}
          test{" "}
        </PdfdisplayProtect>
      )
    }

    setFileRendered(order)

    resolve(true)
  })
}
